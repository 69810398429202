import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'

import '../scss/study-results.scss'

import DownloadAction from '../components/DownloadAction'
import PageSuggestionBanner from '../components/PageSuggestionBanner'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
	data: any
	location: Location
}

const StudyResults = ({ data, location }: Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'Study Results | EXKIVITY® (mobocertinib)',
					description:
						'View study results for treatment of EGFR Exon20 insertion-positive non-small cell lung cancer (NSCLC) after chemotherapy containing platinum with EXKIVITY® (mobocertinib). See Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>
			<Hero bgImage={data.heroImage} imageAlt=''>
				<h1 className='text-white'>
					EXKIVITY Was Shown <br className='hidden lg:block' />
					to Shrink Tumors
				</h1>
			</Hero>
			<div className='grid grid-cols-12' id='study-results-container'>
				<div className='col-start-2 col-span-10 mt-10'>
					<h2 className='text-pompadourViolet font-bold md:text-2xl text-xl mb-2'>
						Studied in people like you
					</h2>
					<div>
						The risks and bene<span className='tracking-wide'>fi</span>ts of EXKIVITY
						<sup>&#174;</sup> (mobocertinib) were studied in 114 adults with
						metastatic non-small cell lung cancer (NSCLC) who had{' '}
						<span className='font-bold'>
							epidermal growth factor receptor (EGFR) Exon20 insertion
						</span>{' '}
						mutations. All people had been previously treated with chemotherapy
						containing platinum.
					</div>
				</div>
				<div className='col-start-2 col-span-10 mt-5'>
					<div className='text-mediumVioletRed text-lg mb-1'>In the study:</div>
					<div className='list'>
						<ul className='list-disc'>
							<li className=' mb-1'>
								All people received the recommended dosing regimen of 160 mg of EXKIVITY
								orally once daily
							</li>
							<li className=' mb-1'>Ages ranged from 27 years old to 84 years old</li>
							<li className=' mb-1'>Two-thirds were female</li>
							<li className=' mb-1'>Most had never smoked (71%)</li>
							<li className=' mb-1'>
								People were from all around the world: 53 from the United States, 6 from
								the Europe, and 55 from the Asia Pacific region
							</li>
						</ul>
					</div>
				</div>
				<div className='col-start-2 col-span-10 md:my-20 my-10'>
					<h2 className='text-pompadourViolet font-bold md:text-2xl text-xl mb-2 text-center md:mb-14 mb-8'>
						Clinical results with EXKIVITY
					</h2>
					<div className='grid grid-cols-12'>
						<div className='md:col-start-2 md:col-span-10 col-span-12'>
							<div className='mb-4 lg:text-2xl'>
								EXKIVITY{' '}
								<span className='font-bold text-mediumVioletRed'>
									shrank tumors by about a third
								</span>{' '}
								or more for approximately
							</div>
							<StaticImage
								src='../images/study-results/3-in-10-people.png'
								alt='A statistic that reads “Approximately 3 in 10 people, with a median length of response of 17.5 months.”'
								className='!hidden	lg:!block'
								layout='fullWidth'
								objectFit='fill'
							/>
							<StaticImage
								src='../images/study-results/3-in-10-people-mobile.png'
								alt='A statistic that reads “Approximately 3 in 10 people, with a median length of response of 17.5 months.”'
								className='lg:!hidden'
							/>
							<div className='list'>
								<ul className='list-disc mt-10'>
									<li className='mb-4'>
										Meaning, half of the people who responded to EXKIVITY did so for 17.5
										months or longer, and half responded for less than 17.5 months
									</li>
									<li className='mb-4'>
										Approximately 60% of the people who responded to EXKIVITY did so for 6
										months or longer
									</li>
								</ul>
							</div>
							<div>
								In this study, it was likely that the majority of people who responded
								to EXKIVITY saw responses that lasted anywhere between 7.4 months and
								20.3 months.
							</div>
						</div>
					</div>
				</div>

				<div className='col-start-2 col-span-10 mb-10'>
					<span className='font-bold text-mediumVioletRed'>
						The most common side effects
					</span>{' '}
					seen in the EXKIVITY clinical study included diarrhea, rash, nausea, mouth
					sores, vomiting, decreased appetite, infection of skin around nails,
					tiredness, dry skin, and muscle or bone pain.
					<div className='mt-16'>
						<DownloadAction
							text='Download the Patient Brochure'
							fileURL='/brochures/Exkivity Patient Education Brochure.pdf'
							images={{
								desktop:
									data.downloadPatientBrochureDesktop.childImageSharp.gatsbyImageData,
								mobile:
									data.downloadPatientBrochureMobile.childImageSharp.gatsbyImageData,
							}}
						/>
					</div>
				</div>
			</div>
			<div className='bg-lightGrey grid grid-cols-12'>
				<div className='col-start-2 col-span-10'>
					<PageSuggestionBanner
						header='Starting EXKIVITY'
						text={'Learn about taking oral EXKIVITY.'}
						buttonText='See EXKIVITY dosing'
						to='/taking-exkivity'
					/>
				</div>
			</div>
		</Layout>
	)
}

export default StudyResults

export const query = graphql`
	{
		heroImage: file(
			relativePath: { eq: "study-results/hero-study-results.png" }
		) {
			...heroImage
		}

		downloadPatientBrochureMobile: file(
			relativePath: { eq: "resources-patient-brochure-thumb.png" }
		) {
			...downloadActionImage
		}
		downloadPatientBrochureDesktop: file(
			relativePath: { eq: "patient-brochure-thumb.png" }
		) {
			...downloadActionImage
		}
	}
`
